import React, { useContext, useEffect, useState } from "react";
import { getCall, postCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import VideoCallUI from "../../SA-VIdeo-Call/components/Layouts/VideoCallUI";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { GiCoffeeCup } from "react-icons/gi";
import DraggableSpeedDial from "./DraggableSpeedDial";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { IoMdRefreshCircle } from "react-icons/io";
import "./meeting-lite.scss";
import { Bars, ThreeDots } from "react-loader-spinner";
import { renderForm } from "../../utils/form";
import { handleAgentFeedbackSubmit } from "./handleAgentFeedbackSubmit";
import { MdContentCopy } from "react-icons/md";

function MeetingLite() {
  const { User } = useContext(UserContext);
  const [callRequest, setCallRequest] = useState();
  // eslint-disable-next-line
  const [meetingData, setMeetingData] = useState();
  const [widgetSourceData, setWidgetSourceData] = useState();
  const [widgetCreds, setWidgetCreds] = useState();
  const [activeRequestOpen, setActiveRequestOpen] = useState(false);
  const [renderModalData, setRenderModalData] = useState("active_request");
  const [isLoading, setIsLoading] = useState({ value: true, text: "" });
  const [formButton, setFormButton] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");
  const navigate = useNavigate();

  const callRequestWidgetData = [
    { "Widget name": "source_name" },
    { Referrer: "source_referrer" },
    { Product: "source_referrer" },
    { Type: "message_type" },
  ];

  const callRequestUserData = [
    { Name: "name" },
    { "Country code": "country_code" },
    { "Mobile number": "mobile_number" },
    { City: "widget_city" },
    { State: "widget_state" },
    { Country: "widget_country" },
    { "IP address": "widget_ip_address" },
    { "Location source": "widget_location_source" },
  ];

  // step 1: get the running meeting details
  let { id } = useParams();

  // step 1: check if the incoming request is not picked up
  const acceptIncomingRequest = () => {
    setIsLoading({ value: true, text: "Checking for incoming request" });

    postCall({
      url: config.url.SOURCES,
      path: "/agent/attend_incoming_request/" + id,
      data: {},
      onSuccess: (response) => {
        getRunningCallAgent();
      },
      onFailure: (error) => {
        getRunningCallAgent();
      },
    });
  };

  // step 2: get the running meeting details
  const getRunningCallAgent = () => {
    setIsLoading({ value: true, text: "Fetching the active meeting details" });

    getCall({
      url: config.url.SOURCES,
      path: "/agent/get_running_call",
      onSuccess: (response) => {
        setCallRequest(response.data.call_request);
        setMeetingData(response.data.meeting);
        fetchJoinLink(response.data.meeting.id);
        fetchWidgetSourceData(response.data.call_request.source_id);
      },
      onFailure: (error) => {
        if (error.response && error.response.data) {
          bottomAlert(
            "Unable to accept incoming call request, and you have no active call " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "error"
          );
        }
        setIsLoading({ value: false, text: "" });
      },
    });
  };

  // step 3: fetch join url link
  const fetchJoinLink = (meeting_id) => {
    setIsLoading({
      value: true,
      text: "Fetching the joining link for meeting",
    });

    getCall({
      url: config.url.ACTIONS,
      path: "/get_join_url/" + meeting_id + "/public",
      onSuccess: (response) => {
        const room_id = response.data.match(/\/([\w-]+)\?/)[1];
        setIsLoading({ value: true, text: "Joining the meeting" });

        postCall({
          url: config.url.ACTIONS,
          path: `/meeting/join_channel/${room_id}/${User?.user_name}`,
          onSuccess: (response) => {
            setWidgetCreds(response.data);
            setIsLoading({ value: false, text: "" });
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to fetch join url. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "error"
        );
        setIsLoading({ value: false, text: "" });
      },
    });
  };

  // step 4: fetch widget source data
  const fetchWidgetSourceData = (widget_id) => {
    getCall({
      url: config.url.SOURCE,
      path: `/${widget_id}`,
      onSuccess: (response) => {
        setWidgetSourceData(response.data);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to fetch widget source data. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "error"
        );
      },
    });
  };

  useEffect(() => {
    acceptIncomingRequest();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const extractProductUrl = (url) => {
    try {
      const urlParams = new URLSearchParams(new URL(url).search);
      return decodeURIComponent(urlParams.get("product_url"));
    } catch (error) {
      return null;
    }
  };

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        bottomAlert("Copied to Clipboard", "info");
      })
      .catch((err) => {
        bottomAlert(`Failed to copy: ${err}`, "error");
        console.error("Failed to copy: ", err);
      });
  };

  const endRequest = (request_id, isEndEveryone) => {
    if(isEndEveryone) {
      postCall({
        url: config.url.SOURCES,
        path: "agent/end_request/" + request_id,
        onSuccess: (response) => {
          navigate("/meetings/lite");
          setWidgetCreds();
          setCallRequest();
          setMeetingData();
        },
        onFailure: (error) => {
          bottomAlert(
            "Unable to end the session here." +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
        },
      });
    } else {
      navigate("/meetings/lite");
      setWidgetCreds();
      setCallRequest();
      setMeetingData();
    }
  };

  return isLoading.value ? (
    <div className="saleassist-meeting-lite-loader-wrapper">
      <Bars
        height="60"
        width="60"
        color="#959595"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      {isLoading.text}
    </div>
  ) : (
    <div className="saleassist-meeting-lite">
      {callRequest && widgetCreds ? (
        <>
          <VideoCallUI
            widget_creds={widgetCreds}
            meeting_type={callRequest?.message_type}
            meetingData={{
              id: callRequest?.meeting_id,
              people_id: callRequest?.people_id,
            }}
            endCallBack={(isEndEveryone) => endRequest(callRequest?.id, isEndEveryone)}
          />

          <Dialog
            onClose={() => setActiveRequestOpen(false)}
            open={activeRequestOpen}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle className="sa-active-request-modal-title">
              {renderModalData.replace("_", " ")}
              <IoClose onClick={() => setActiveRequestOpen(false)} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* render the agent feedback form inside modal */}
                {renderModalData === "agent_feedback" && (
                  <form
                    onSubmit={(e) =>
                      handleAgentFeedbackSubmit(
                        e,
                        JSON.parse(widgetSourceData.agent_feedback_form),
                        setFormButton,
                        setFormErrorText,
                        setActiveRequestOpen,
                        callRequest,
                        User
                      )
                    }
                    className="sa-agent-feedback-form"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: renderForm(
                          JSON.parse(widgetSourceData.agent_feedback_form)
                        ),
                      }}
                    ></div>

                    <p className="sa-agent-feedback-form-error-text">
                      {formErrorText}
                    </p>

                    <button
                      type="submit"
                      className="sa-agent-feedback-form-submit-button"
                      style={{ cursor: formButton ? "not-allowed" : "pointer" }}
                    >
                      {formButton ? (
                        <ThreeDots
                          visible={true}
                          height="16"
                          width="80"
                          color="#fff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{ placeContent: "center" }}
                          wrapperClass=""
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                )}

                {/* render the active request data inside modal */}
                {renderModalData === "active_request" && (
                  <>
                    {callRequestWidgetData.map((data, index) => {
                      var key = Object.keys(data);
                      var productUrl = key[0] === "Product" && extractProductUrl(callRequest[data[key]]);

                      return productUrl && productUrl !== "null" ? (
                        <div
                          key={`${key}-${index}`}
                          className="sa-active-request-modal-widget-content"
                        >
                          <span>{key}</span>
                          <span onClick={() => copyToClipboard(productUrl)} >
                            {productUrl}
                            <MdContentCopy />
                          </span>
                        </div>
                      ) : (
                        <div
                          key={`${key}-${index}`}
                          className="sa-active-request-modal-widget-content"
                        >
                          <span>{key}</span>
                          <span onClick={() => copyToClipboard(callRequest[data[key]])} >
                            {callRequest[data[key]]}
                            <MdContentCopy />
                          </span>
                        </div>
                      );
                    })}

                    <div className="sa-active-request-modal-user-content-wrapper">
                      {callRequestUserData.map((data, index) => {
                        var key = Object.keys(data);
                        return (
                          <div
                            key={`${key}-${index}`}
                            className="sa-active-request-modal-user-content"
                          >
                            <span>{key}</span>
                            <span>{callRequest.user_data[data[key]]}</span>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </DialogContentText>
            </DialogContent>
          </Dialog>

          <DraggableSpeedDial
            callRequest={callRequest}
            activeRequestHandle={() => setActiveRequestOpen(true)}
            renderModalDataHandle={(data) => setRenderModalData(data)}
          />
        </>
      ) : (
        <div className="no-active-call-placeholder">
          <GiCoffeeCup />
          <p>
            Currently no active calls,<span>Grab a coffee!</span>
            <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              or{" "}
              <Tooltip title="Reload Page" arrow>
                <span
                  style={{
                    marginRight: "auto",
                    cursor: "pointer",
                    fontSize: 26,
                    display: "flex",
                  }}
                  onClick={() => window.location.reload()}
                >
                  <IoMdRefreshCircle color="#959595" size={28} />
                </span>
              </Tooltip>
            </span>
          </p>
          {/* <Link className="go-home-button" to="/meetings/schedule">
            Go Home
          </Link> */}
        </div>
      )}
    </div>
  );
}

export default MeetingLite;
