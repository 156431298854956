import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { config } from "../../../config";
import VideoCall from "../../js/core/VideoCall";
import CameraOnButton from "../Buttons/camera-on";
import CloseIconButton from "../Buttons/close-icon";
import EndCallButton from "../Buttons/end-call";
import FlipCameraButton from "../Buttons/flip-camera";
import MessageIconButton from "../Buttons/message";
import MicOnButton from "../Buttons/mic-on";
import MoreOptionButton from "../Buttons/more-option";
import PhoneHoldIcon from "../Buttons/phone-hold-icon";
import PipModeButton from "../Buttons/pip-mode";
import RecordIcon from "../Buttons/record-icon";
import ScreenShareOnButton from "../Buttons/screen-share-on";
import SendIconButton from "../Buttons/send";
import SettingsButton from "../Buttons/settings";
import ShareUrlButton from "../Buttons/share-url";
import UploadIconButton from "../Buttons/upload";
import VideoBackgroundBlur from "../Buttons/video-background-blur";
import VideoBackgroundColor from "../Buttons/video-background-color";
import VideoBackgroundImage from "../Buttons/video-background-image";
import VideoCallTilesToggleButton from "../Buttons/video-call-tiles-toggle";
import VirtualBackground from "../Buttons/virtual-bg";
import ScreenshotIcon from "../Buttons/screenshot-icon";
import UserCountIconButton from "../Buttons/user-count-icon";
import { ClientContext } from "../../../context/ClientContext";
import { UserContext } from "../../../context/UserContext";
import { ThreeDots } from "react-loader-spinner";
import { SocketContext } from "../../../context/SocketContext";
import InviteUserButton from "../Buttons/invite-user";
import CallTransferButton from "../Buttons/call-transfer";
import { getCall, putCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import NetworkIcon from "../Buttons/network-icon";
import SwipeToUnhold from "../SwipeToUnhold/SwipeToUnhold";

function VideoCallUI({
  //   handleMinMax,
  //   maximize,
  //   noMaximize,
  widget_creds,
  meeting_type,
  meetingData,
  endCallBack,
  liveStream,
  viewerCount,
  highlightProducts,
  conference,
  // onHighlightProducts,
}) {
  const { User } = useContext(UserContext);
  const { Client } = useContext(ClientContext);
  const { Socket, setSocket } = useContext(SocketContext);
  const [showVideo, setShowVideo] = useState(false);
  const [endCall, setEndCall] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState(null);
  const [filterBy, setFilterBy] = useState("email");
  const [videoCall, setVideoCall] = useState();

  useLayoutEffect(() => {
    setVideoCall(
      new VideoCall(
        widget_creds,
        meeting_type,
        meetingData,
        endCallBack,
        User,
        Client,
        Socket,
        setSocket
      )
    );
    // eslint-disable-next-line
  }, []);

  function copyToClipboard() {
    const customDomain = Client.dns_domain?.trim();
    // Get the URL that you want to copy
    const urlToCopy = `${
      customDomain || config.url.BASE
    }/actions/join_meeting/${meetingData.id}/ask`;

    // Create a temporary input element
    var tempInput = document.createElement("input");
    tempInput.setAttribute("type", "text");
    tempInput.setAttribute("value", urlToCopy);
    document.body.appendChild(tempInput);

    // Select the input element's text
    tempInput.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    const notificationPopupEl = document.querySelector(
      ".url-copied-indication-popup"
    );

    notificationPopupEl.classList.add("url-copied-indication-popup-show");

    setTimeout(() => {
      notificationPopupEl.classList.remove("url-copied-indication-popup-show");
    }, 3000);
  }

  function fetchUsers(selectedWidgets = null) {
    getCall({
      path: "users/all",
      onSuccess: (response) => {
        let agents = response.data
          .filter((user) => user.email !== User.email)
          .map((user) => user);

        if (selectedWidgets?.agents) {
          agents = agents.filter((elem) =>
            selectedWidgets.agents.default.includes(elem.email)
          );
        }

        setAgents(agents);
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  }

  function onSelectedAgentsChange(event, selectedAgents) {
    setSelectedAgents(selectedAgents);
  }

  function onSelectedWidgetsChange(event, selectedWidgets) {
    setSelectedWidgets(selectedWidgets);
    fetchUsers(selectedWidgets);
  }

  function handleSubmit() {
    if (selectedAgents.length > 0) {
      putCall({
        path: `meetings/${meetingData.id}/invite_agent`,
        data: {
          invite_agent: selectedAgents.map((agent) => agent.email),
        },
        onSuccess: (response) => {
          bottomAlert("Invited User", "success");
        },
        onFailure: (error) => {
          bottomAlert(
            "Unable to complete your request. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
        },
      });
    } else {
      bottomAlert("Please select agent for invite! ", "warning");
    }
  }

  function askPictureInPictureMode() {
    if (meetingData.people_id) {
      Socket?.socket.emit("chat_message", {
        show_pip_mode: true,
        label: "Enable PIP Mode",
        people_id: meetingData.people_id,
      });
    } else {
      Socket?.socket.emit("chat_message", {
        show_pip_mode: true,
        label: "Enable PIP Mode",
        meeting_id: meetingData.id,
      });
    }
    bottomAlert("successfully sent request for pip mode!", "success");
  }

  const AgentWithStatusItem = (props, option) => (
    <Box component="li" {...props}>
      <span
        style={{
          width: 10,
          height: 10,
          background: option.in_call
            ? "#ff0000"
            : option.is_available
            ? "#3ac47d"
            : "#ccc",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      ></span>
      {filterBy === "email" ? option.email : option.display_name}
    </Box>
  );

  const handleFilterBy = (event) => {
    setFilterBy(event.target.value);
  };

  useEffect(() => {
    fetchUsers();

    getCall({
      url: config.url.SOURCES,
      onSuccess: (response) => {
        setWidgets(response.data);
      },
      onFailure: (error) => {
        console.log(error);
      },
    });

    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return async () => {
      if (
        Socket?.agoraClient &&
        Socket?.agoraLocalAudioTrack &&
        Socket?.agoraLocalVideoTrack
      ) {
        Socket.agoraLocalAudioTrack?.close();
        Socket.agoraLocalVideoTrack?.close();

        await Socket.agoraClient.leave();
        setSocket((prev) => ({
          ...prev,
          agoraClient: "",
          agoraLocalAudioTrack: "",
          agoraLocalVideoTrack: "",
        }));
      }
    };
    // eslint-disable-next-line
  }, [
    Socket?.agoraClient,
    Socket?.agoraLocalAudioTrack,
    Socket?.agoraLocalVideoTrack,
  ]);

  useEffect(() => {
    videoCall?.initSocket();

    return () => {
      Socket?.socket?.removeAllListeners("message");
      Socket?.socket?.removeAllListeners("chat_message");
    };
    // eslint-disable-next-line
  }, [Socket?.socket]);

  return (
    <>
      <div className="saleassist-video-call-loader">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#fff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p>
          Getting ready...
          <br />
          You'll be able to join in just a moment
        </p>
      </div>

      <div
        id="videoCallContainer"
        className={`${liveStream ? "live-stream" : ""}`}
      >
        <div className="url-copied-indication-popup">
          <span>URL Copied To Clipboard!</span>
        </div>

        <div className="on-hold-indication-popup">
          <SwipeToUnhold/>
        </div>

        <div className="sa-video-call-top-bar">
          <div id="sa-pip-mode" className="sa-video-call-top-bar-button">
            <PipModeButton />
          </div>
          <div
            className="sa-video-call-top-bar-button"
            onClick={copyToClipboard}
          >
            <ShareUrlButton />
          </div>
          {!liveStream && (
            <div
              className="sa-video-call-top-bar-button"
              onClick={() => setShowVideo(true)}
            >
              {conference ? <InviteUserButton /> : <CallTransferButton />}
            </div>
          )}
          {/* <div
            className="sa-video-call-top-bar-button"
            style={{ display: noMaximize ? "none" : "flex" }}
            onClick={() => handleMinMax()}
          >
            {maximize ? <MinimizeButton /> : <MaximizeButton />}
          </div> */}
        </div>

        {showVideo && (
          <div className="sa-video-call-popup-panel-container">
            <div className="header-container">
              <span>{conference ? "Invite Agents" : "Call Transfer"}</span>
              <span className="close-icon" onClick={() => setShowVideo(false)}>
                <CloseIconButton />
              </span>
            </div>

            <div className="content-container">
              <Autocomplete
                color="primary"
                options={widgets}
                value={selectedWidgets}
                getOptionLabel={(option) => option.name}
                onChange={onSelectedWidgetsChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Widget"
                    style={{ color: "#fff" }}
                  />
                )}
              />

              <div className="helper-text" style={{ marginBottom: 15 }}>
                Filter the agents based on widget type using the dropdown above.
              </div>

              <fieldset className="sa-video-call-popup-panel-filter-by-container">
                <legend className="title">Filter by</legend>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={filterBy}
                  onChange={handleFilterBy}
                >
                  <FormControlLabel
                    value="name"
                    control={<Radio size="small" />}
                    label="Name"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Radio size="small" />}
                    label="Email"
                  />
                </RadioGroup>
              </fieldset>

              <Autocomplete
                multiple
                filterSelectedOptions
                options={agents}
                value={selectedAgents}
                getOptionLabel={(option) =>
                  filterBy === "email" ? option.email : option.display_name
                }
                onChange={onSelectedAgentsChange}
                renderOption={AgentWithStatusItem}
                renderInput={(params) => (
                  <TextField {...params} label="Agent" />
                )}
              />

              <div className="helper-text">
                Please select the agent{" "}
                {conference ? "to invite for meeting" : "for call tranfer"} from
                the dropdown above.
              </div>

              <button className="submit-button" onClick={handleSubmit}>
                Send Invite
              </button>
            </div>
          </div>
        )}

        <div
          className="sa-video-call-popup-panel-container"
          style={{ display: endCall ? "flex" : "none" }}
        >
          <div className="header-container">
            <span>End Meeting</span>
            <span
              className="close-icon"
              onClick={() => {
                setEndCall(false);
              }}
            >
              <CloseIconButton />
            </span>
          </div>

          <div className="content-container">
            <div
              className="helper-text"
              style={{ marginBottom: 15, textAlign: "center" }}
            >
              Are you sure you want to end the meeting?
            </div>
            <Button
              id="end-meeting-for-me"
              color="error"
              variant="contained"
              size="small"
              disableElevation
            >
              End Call
            </Button>
            {/* <Button
              id="end-meeting-for-everyone"
              color="error"
              variant="contained"
              size="small"
              disableElevation
            >
              End for Everyone
            </Button> */}
          </div>
        </div>

        {liveStream && (
          <div className="sa-live-stream-info-bar">
            <div className="sa-live-stream-live-button">Go Live</div>
            <div className="sa-live-stream-info">
              <UserCountIconButton />
              <span>{viewerCount}</span>
            </div>
          </div>
        )}

        {liveStream && highlightProducts?.length > 0 && (
          <div className="liveproductselect">
            {/* <SlideShow
                            style={{ height: "100%" }}
                            onSelect={(index) => onHighlightProducts(index)}
                            slides={highlightProducts.map((product, index) => {
                            return (
                                <div
                                  style={{ height: "100%", width: "100%" }}
                                  key={product.id}
                                >
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      alt={"highlighted product " + product.product_name}
                                      src={
                                      product.product_display_image
                                        ? product.product_display_image
                                        : product.product_images.length > 0
                                        ? product.product_images[0]
                                        : ""
                                      }
                                    />
                                    <div
                                      className="priceTag"
                                      style={{
                                        color: "#fff",
                                        fontWeight: "600",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                        background: "rgb(0,0,0,0.3)",
                                        padding: "0px 5px",
                                        width: "fit-content",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                      }}
                                    >
                                      {product.product_currency} {product.product_price}
                                    </div>
                                </div>
                            );})}
                        /> */}
          </div>
        )}

        <div id="videoContainer">
          <div id="videoCallMainScreen">
            <div
              id="localVideoContainer"
              className={`video-call-wrapper active-speaker-${widget_creds.widget_creds.uid}`}
              user-name={widget_creds.user_display_name[0].toUpperCase()}
            >
              <span id="localUserPlaceholder">
                <span className="speaking-wave-effect"></span>
                <span className="user-display-name">
                  {widget_creds.user_display_name[0].toUpperCase()}
                </span>
              </span>
              <span
                className="mic-status-indicator"
                user-name={widget_creds.user_display_name}
              >
                <MicOnButton />
              </span>
              <span className="network-quality-info">
                <NetworkIcon />
              </span>
              <span className="username-indicator">
                {widget_creds.user_display_name}
              </span>
              <span className="volume-indicator">
                <span id="bar1" className="bars"></span>
                <span id="bar2" className="bars"></span>
                <span id="bar3" className="bars"></span>
              </span>
            </div>
          </div>
          <div
            id="videoCallVideoTiles"
            className="video-call-video-tiles-transition"
          ></div>
          <div className="videoCallVideoTilesToggleBtn">
            <VideoCallTilesToggleButton />
          </div>
        </div>

        <div className="saleassist-video-call-more-options-control">
          <button
            id="recordButton"
            className="control-button more-control-button"
          >
            <RecordIcon />
            <span className="more-control-helper-text">Record Call</span>
          </button>
          <button
            id="sa-hold-call"
            className="control-button more-control-button"
          >
            <PhoneHoldIcon />
            <span className="more-control-helper-text">Hold Call</span>
          </button>
          <button
            id="sa-ask-pip"
            className="control-button more-control-button"
            onClick={askPictureInPictureMode}
          >
            <PipModeButton />
            <span className="more-control-helper-text">Ask PIP</span>
          </button>
          <button
            id="sa-virtual-background"
            className="control-button more-control-button"
          >
            <VirtualBackground />
            <span className="more-control-helper-text">Virtual BG</span>
          </button>
          <button
            id="sa-settings"
            className="control-button more-control-button"
          >
            <SettingsButton />
            <span className="more-control-helper-text">Settings</span>
          </button>
        </div>

        <div id="saleassist-video-call-controls">
          <button id="screenShare" className="control-button">
            <ScreenShareOnButton />
          </button>
          <button id="toggleCamera" className="control-button">
            <CameraOnButton />
          </button>
          <button id="toggleAudio" className="control-button">
            <MicOnButton />
          </button>
          <button id="flipCamera" className="control-button">
            <FlipCameraButton />
          </button>
          <button id="sa-message" className="control-button">
            <span className="sa-message-notification"></span>
            <MessageIconButton />
          </button>
          <button
            id="leaveButton"
            className="control-button"
            onClick={() => setEndCall(true)}
          >
            <EndCallButton />
          </button>
          <button id="moreButton" className="more-option-button">
            <MoreOptionButton />
          </button>
        </div>

        <div id="inputDeviceSettings">
          <div className="input-device-settings-header">
            <span>Device Setting</span>
            <span id="inputDeviceSettingsClose">
              <CloseIconButton />
            </span>
          </div>
          <div id="videoInputDeviceSetting" className="input-device-settings">
            <span>
              <CameraOnButton />
            </span>
          </div>
          <div id="audioInputDeviceSetting" className="input-device-settings">
            <span>
              <MicOnButton />
            </span>
          </div>
        </div>

        <div id="virtualBackgroundWindow">
          <div className="virtual-background-window-header">
            <span>Virtual Background</span>
            <label className="sa-video-call-switch">
              <input id="virtualBackgroundSlider" type="checkbox" />
              <span className="sa-video-call-slider"></span>
            </label>
            <span id="virtualBackgroundWindowClose">
              <CloseIconButton />
            </span>
          </div>

          <div className="virtual-background-window-tabs-cont">
            <div className="virtual-backgorund-window-tabs-overlay"></div>
            <div className="virtual-background-window-tabs">
              <span className="tab-text-Button active" id="blur">
                Blur
              </span>
              <span className="tab-text-Button" id="color">
                Color
              </span>
              <span className="tab-text-Button" id="image">
                Image
              </span>
            </div>

            <div className="virtual-background-tab-content">
              <VideoBackgroundBlur />
              <VideoBackgroundColor />
              <VideoBackgroundImage />
            </div>
          </div>
        </div>
      </div>

      <div className="saleassist-video-call-chat-container sa-chat-hidden">
        <div className="saleassist-video-call-chat-header">
          <span>In-Call Message</span>
          <span id="saleassistVideoChatCloseButton">
            <CloseIconButton />
          </span>
        </div>

        <div
          className="saleassist-video-call-chat-log"
          id="saleassistVideoChatLog"
        >
          {/* Chat messages will be displayed here  */}
        </div>

        <div className="saleassist-video-call-chat-input-box">
          <span className="chat-input-overlay">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#fff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </span>
          <div className="saleassist-video-call-document-preview-container">
            {/* Uploaded files will be displayed here */}
          </div>

          <div className="saleassist-video-call-chat-input-container">
            <div className="saleassist-video-call-chat-input-wrapper">
              <input
                type="text"
                id="saleassistVideoChatMessageInput"
                placeholder="Type Your Message"
              />
              <span id="saleassistVideoChatScreenshotButton">
                <ScreenshotIcon />
              </span>
              <label htmlFor="upload-file">
                <span id="saleassistVideoChatUploadButton">
                  <UploadIconButton />
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    accept="*"
                    multiple
                  />
                </span>
              </label>
            </div>
            <button id="saleassistVideoChatSendButton">
              <SendIconButton />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoCallUI;
